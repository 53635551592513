<template>
  <v-dialog v-model="modalData.dialog" max-width="1150px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2 ml-4"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Category
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }}</span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="max-height: 90%">
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select v-model="selectedLocale" :items="languages">
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <!-- <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.companySettings.department"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col> -->
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  :key="selectedLocale.lang + '-name'"
                  :rules="nameRules"
                  label="Category name"
                  :id="dynamicID"
                  :error-messages="
                    messages['translations.' + selectedLocale.lang + '.name']
                  "
                  @keyup="
                    messages['translations.' + selectedLocale.lang + '.name'] =
                      ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <div v-if="this.editedItem.id">
                  <!-- <v-select
                    v-model="formModel.category_id"
                    :items="parentCategories"
                    item-text="name"
                    item-value="id"
                    label="Parent category"
                    :rules="currencyRules"
                    :id="dynamicID"
                  ></v-select> -->

                  <treeselect
                    class="mt-4 mb-5"
                    label="Parent category"
                    :options="parentCategoriesTree"
                    :default-expand-level="0"
                    placeholder="Parent category"
                    v-model="formModel.category_id"
                  />
                  <pre>{{ categoriesTree }}</pre>
                </div>
                <div v-else>
                  <!-- <v-select
                    v-model="formModel.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Parent category"
                    :rules="currencyRules"
                    :id="dynamicID"
                  ></v-select> -->

                  <treeselect
                    class="mt-4 mb-5"
                    label="Parent category"
                    :options="categoryTree"
                    :default-expand-level="0"
                    placeholder="Parent category"
                    v-model="formModel.category_id"
                  />
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h5>List of items on this category</h5>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete
                  v-model="autoCompleteItem"
                  :items="filteredItemCollection"
                  item-text="ReferenceName"
                  label="Search item"
                  return-object
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-btn
                  depressed
                  color="primary"
                  class="ma-2"
                  @click="handleAddItem"
                  :disabled="!autoCompleteItem.id"
                >
                  Add item
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="formModel.itemObjects"
                  :search="filterData.search"
                  class="elevation-1"
                >
                  <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header">
                      <tr>
                        <th colspan="2">
                          <v-text-field
                            v-model="filterData.search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </th>
                        <th>
                          <p style="margin-bottom: -16px">Can be sold</p>
                          <v-checkbox v-model="filterData.sale"> </v-checkbox>
                        </th>
                        <th>
                          <p style="margin-bottom: -16px">
                            Can be manufactured
                          </p>
                          <v-checkbox v-model="filterData.manufacture">
                          </v-checkbox>
                        </th>
                        <th>
                          <p style="margin-bottom: -16px">Can be purchased</p>
                          <v-checkbox v-model="filterData.purchase">
                          </v-checkbox>
                        </th>
                        <th>
                          <p style="margin-bottom: -16px">Service</p>
                          <v-checkbox v-model="filterData.service">
                          </v-checkbox>
                        </th>
                        <th>
                          <v-btn
                            class="mx-1"
                            fab
                            dark
                            small
                            color="pink"
                            @click="
                              filterData = Object.assign({}, initialFilterData)
                            "
                          >
                            <v-icon dark> mdi-delete </v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                  </template>

                  <template v-slot:item.sale="{ item }">
                    <v-simple-checkbox
                      v-model="item.sale"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:item.manufacture="{ item }">
                    <v-simple-checkbox
                      v-model="item.manufacture"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:item.purchase="{ item }">
                    <v-simple-checkbox
                      v-model="item.purchase"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:item.service="{ item }">
                    <v-simple-checkbox
                      v-model="item.service"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <!-- <v-simple-checkbox
                      v-model="item.service"
                      disabled
                    ></v-simple-checkbox>
                     -->
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && isNotEditedItem) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { ENDPOINT } from "./Categories";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { categoryTreeMixins } from "@/view/mixins/categoryTreeMixins.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = () => ({
  id: null,
  status: 1,
  categories: [],
  parentCategories: [],
  category_id: null,
  translations: {},
  items: [],
  itemObjects: [],
});

const initialFilterData = {
  search: "",
  purchase: false,
  sale: false,
  manufacture: false,
  service: false,
};

export default {
  name: "CategoryModalForm",
  props: [
    "modalData",
    "editedItem",
    "permissions",
    "categories",
    "itemCollection",
  ],
  mixins: [formModelMixins, categoryTreeMixins],
  components: { Treeselect },
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: initialFormData(),
      formValid: false,
      selectedItem: {},
      dialogDelete: false,
      parentCategories: [],
      filterData: Object.assign({}, initialFilterData),
      initialItems: [],
      messages: {},
      autoCompleteItem: {},
      headers: [
        { text: "Reference", value: "reference" },
        {
          text: "Item name",
          value: "name",
        },
        { text: "Can be sold", value: "sale", searchable: false },
        {
          text: "Can be manufactured",
          value: "manufacture",
          searchable: false,
        },
        { text: "Can be purchased", value: "purchase", searchable: false },
        { text: "Service", value: "service", searchable: false },
        { text: "Action", value: "action", searchable: false },
      ],
      nameRules: [
        (v) => !!v || "Category name is required",
        (v) =>
          (!!v && v.length > 2) || "Category name must be min 3 characters",
      ],
    };
  },
  computed: {
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    isNotEditedItem() {
      if (this.editedItem.id) {
        return 0;
      } else {
        return 1;
      }
    },
    // ...mapGetters([CURRENCY_COLLECTION]),
    formTitle() {
      return this.editedItem.id ? "Edit category" : "New category";
    },

    filteredItemCollection() {
      if (!this.formModel.itemObjects) return [];
      let itemIds = this.formModel.itemObjects.map((item) => item.id);
      return this.itemCollection.filter((item) => {
        return !itemIds.includes(item.id);
      });
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },
    parentCategoriesTree() {
      if (!this.formModel.id || !this.categoryTree) return [];
      return this.categoryTree.filter((item) => item.id != this.formModel.id);
    },
  },

  watch: {
    editedItem(value) {
      this.selectedLocale = i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      });
      this.setParentCategories(this.editedItem.id);
      this.formModel = value;
      this.initialItems = this.formModel.itemObjects;
      this.setTranslatedAttributes();
      this.resetErrorMessages();
    },
    filterData: {
      deep: true,
      handler(value) {
        if (
          !value.manufacture &&
          !value.purchase &&
          !value.sale &&
          !value.service
        ) {
          this.formModel.itemObjects = this.initialItems;
        } else {
          this.formModel.itemObjects = this.formModel.itemObjects.filter(
            (item) => {
              let activeItem = false;
              if (value.manufacture && item.manufacture) {
                activeItem = true;
              }
              if (value.service && item.service) {
                activeItem = true;
              }
              if (value.sale && item.sale) {
                activeItem = true;
              }
              if (value.purchase && item.purchase) {
                activeItem = true;
              }
              return activeItem;
            }
          );
        }
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm() {
      this.resetErrorMessages();
      this.resetParentCategories();
      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();
      if (!this.formValid) return;

      if (this.formValid) {
        this.resetErrorMessages();
        this.modalData.loading = true;
        this.loader = true;
        model.items = model.itemObjects.map((item) => {
          return item.id;
        });

        if (model.id) {
          // alert("PUT " + model.id);
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.modalData.loading = false;
              this.snackbar = true;
              this.$emit("saveModalForm", Object.assign({}, model));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model

          ApiService.post(ENDPOINT, model)
            .then(({ data }) => {
              this.modalData.loading = false;
              this.snackbar = true;
              this.$emit("saveModalForm", Object.assign({}, data));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
      this.resetParentCategories();
    },

    deleteItem(item) {
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.formModel.itemObjects = this.formModel.itemObjects.filter((item) => {
        return item.id != this.selectedItem.id;
      });
      this.initialItems = this.formModel.itemObjects;
      this.selectedItem = {};
      this.closeDelete();
    },

    closeDelete() {
      this.selectedItem = {};
      this.dialogDelete = false;
    },

    handleNew() {
      this.resetErrorMessages();
      this.setTranslatedAttributes(true);
    },

    handleAddItem() {
      if (this.autoCompleteItem.id) {
        this.formModel.itemObjects.push(this.autoCompleteItem);
        this.initialItems = this.formModel.itemObjects;
        this.autoCompleteItem = {};
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "inventory.itemCategory." + action
      );
    },
    setParentCategories(id) {
      this.parentCategories = this.categories.filter((item) => item.id != id);
    },
    resetParentCategories() {
      this.parentCategories = this.categories;
    },
  },

  async mounted() {},
};
</script>
