export const categoryTreeMixins = {
  computed: {
    webCategoriesTree() {
      if (!this.webCategories) {
        return [];
      }
      return this.webCategories
        .filter((item) => !item.web_category_id)
        .map((item) => {
          let category = {};
          category.id = item.id;
          category.label = item.name;
          if (item.web_categories && item.web_categories.length > 0) {
            category.children = this.getWebCategoryChildren(
              item.web_categories
            );
          }
          return category;
        });
    },
    categoryTree() {
      if (!this.categories) {
        return [];
      }
      return this.categories
        .filter((item) => !item.category_id)
        .map((item) => {
          let category = {};
          category.id = item.id;
          category.label = item.name;
          if (item.categories && item.categories.length > 0) {
            category.children = this.getCategoryChildren(item.categories);
          }
          return category;
        });
    },
  },
  methods: {
    getWebCategoryById(id) {
      let webCategory = this.webCategories.find((item) => item.id == id);
      let webCategoryTree = {
        id: webCategory.id,
        label: webCategory.name,
      };
      if (webCategory.web_categories && webCategory.web_categories.length > 0) {
        webCategoryTree.children = this.getWebCategoryChildren(
          webCategory.web_categories
        );
      }
      return webCategoryTree;
    },

    getWebCategoryChildren(webcategoryIds = []) {
      return webcategoryIds.map((id) => this.getWebCategoryById(id));
    },

    getCategoryById(id) {
      let category = this.categories.find((item) => item.id == id);
      let categoryTree = {
        id: category.id,
        label: category.name,
      };
      if (category.categories && category.categories.length > 0) {
        categoryTree.children = this.getCategoryChildren(category.categories);
      }
      return categoryTree;
    },

    getCategoryChildren(categoryIds = []) {
      return categoryIds.map((id) => this.getCategoryById(id));
    },
  },
};
