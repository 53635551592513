<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Categories
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <CategoryModalForm
            :modalData="modalData"
            :editedItem="editedItem"
            :permissions="permissions"
            :categories="categoryCollection"
            :itemCollection="itemCollection"
            :quantityUnitCollection="quantityUnitCollection"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
          ></CategoryModalForm>

          <AlertComponent
            :alertShow="alert.show"
            :alertText="alert.text"
            @closeAlert="alert.show = false"
          ></AlertComponent>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="ml-9 pb-0"
          style="rgba(0, 0, 0, 0.6);font-size: 0.75rem;"
        >
          <b>Category name</b>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pt-0">
          <v-treeview
            shaped
            hoverable
            activatable
            :items="categoryTree"
            :headers="headers"
            :search="search"
            @update:active="handleEditCategory"
          >
            <template slot="label" slot-scope="{ item }">
              <v-row>
                <v-col cols="7">
                  {{ getTranslated(item) }}
                </v-col>
                <v-col cols="4">
                  No. of Items: {{ item.items_num }}, No. of Categories:
                  {{ item.categories_num }}
                </v-col>
                <v-col cols="1">
                  <v-icon
                    v-if="permissionCan('delete') && item.categories_num == 0"
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </template>
          </v-treeview>
          <!-- <pre>
            {{ categoryTree }}
          </pre> -->
        </v-col>
      </v-row>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <file-manager></file-manager>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import AlertComponent from "@/view/components/AlertComponent";
import CategoryModalForm, { initialFormData } from "./CategoryModalForm";
import i18nService from "@/core/services/i18n.service.js";
// import FileManager from 'laravel-file-manager'

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export const ENDPOINT = "inventory/category/";

export default {
  name: "categories",
  components: { CategoryModalForm, AlertComponent },
  data() {
    return {
      loader: false,
      /*
      settings: {
        // axios headers
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${window.localStorage.getItem("id_token")}`,
        },
        baseUrl: "api/file-manager/", // overwrite base url Axios
        windowsConfig: 2, // overwrite config
        lang: "hu", // set language
        translation: {
          // add new translation
          name: "hueded",
          content: {
            about: "Über",
            back: "Zurück",
          },
        },
      },
*/
      showAll: false,
      search: "",
      collection: [],
      categoryTree: [],
      permissions: [],
      appLocale: i18nService.getActiveLanguage(),
      alert: {
        show: false,
        text: "You can't delete a category that contains either items or categories",
      },
      headers: [
        {
          text: "Category name",
          value: "name",
        },
        {
          text: "Items",
          value: "items_num",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        loading: false,
        // editedItem: Object.assign({}, initialFormData),
      },
      editedItem: Object.assign({}, initialFormData),

      dialogDelete: false,
      routePath: "/inventory/categories/",
    };
  },

  computed: {
    ...mapGetters([
      "categoryCollection",
      "itemCollection",
      "quantityUnitCollection",
    ]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    categoryCollection(val) {
      this.filterCollection(val);
    },
  },

  methods: {
    ...mapActions(["fetchCategory", "fetchItem", "fetchQuantityUnit"]),
    editItem(id) {
      ApiService.get(ENDPOINT + id)
        .then(({ data }) => {
          data.itemObjects = this.itemCollection.filter((item) => {
            return data.items.includes(item.id);
          });

          this.editedItem = Object.assign({}, data);
          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.categories_num > 0) {
        this.alert.show = true;
      } else {
        this.dialogDelete = true;
      }
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchCategory();
          this.fetchCategoryTree();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchCategory();
      this.fetchCategoryTree();
      this.editedItem = initialFormData();
      if (this.$route.name !== "categories")
        this.$router.push({ name: "categories" });
    },

    closeDelete() {
      this.editedItem = Object.assign({}, initialFormData);
      this.dialogDelete = false;
    },

    handleSaveModalForm(item = null) {
      // this.handleCloseModalForm();
      if (this.permissionCan("view") && !this.dialogDelete && item.id) {
        this.$router.push(this.routePath + item.id);
      }
    },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name === "inventory.itemCategory." + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("itemCategory") > -1
        );
      }
    },

    fetchCategoryTree() {
      ApiService.get("inventory/category/tree?locale=" + this.appLocale)
        .then(({ data }) => {
          let dataLocalized = data.items.map((item) => {
            if (item.translations.length == 0) {
              item.name = "Name not defined";
              return item;
            }

            if (item.translations[this.appLocale]) {
              item.name = item.translations[this.appLocale].name;
            } else {
              item.name =
                item.translations[Object.keys(item.translations)[0]].name;
            }

            // console.log(item);
            if (item.children && item.children.length > 0) {
              item.children = this.translateChildren(item.children);
            }

            return item;
          });

          this.categoryTree = dataLocalized;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    getTranslated(item) {
      if (item.translations[this.appLocale]) {
        return item.translations[this.appLocale].name;
      } else {
        return item.translations[Object.keys(item.translations)[0]].name;
      }
    },

    handleEditCategory(item) {
      if (!this.editedItem.id && this.permissionCan("view")) {
        this.$router.push(`/inventory/categories/${item}`);
      }
    },

    translateChildren(childs) {
      let translatedChilds = childs.map((item) => {
        if (item.translations[this.appLocale]) {
          item.name = item.translations[this.appLocale].name;
        } else {
          item.name = item.translations[Object.keys(item.translations)[0]].name;
        }

        if (item.children && item.children.length > 0) {
          item.children = this.translateChildren(item.children);
        }
        return item;
      });
      return translatedChilds;
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Category" }]);
    this.fetchCategoryTree();
    this.fetchCategory();
    this.fetchItem();
    this.fetchQuantityUnit();

    this.setPermissions();
  },
};
</script>
